import React from 'react';
import Section from '../../components/section';
import { PageHero } from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import settginsImg from '../../images/webhook/settings.png';
import webhookImg from '../../images/webhook/webhook.png'
import webhookUrlImg from '../../images/webhook/webhook-url.png'
import monitoringImg from '../../images/webhook/monitoring-urls.png'
import monitoringWebhookImg from '../../images/webhook/monitoring-urls-webhook.png'
import monitoringWebhookInputImg from '../../images/webhook/url-edit.png'
import './styles.scss';

const WebhookIntegration = () => {
  return (
    <div className="webhook-main">
      <Layout>
        <PageHero
          title="Webhook integration"
        // subtitle="This page provides documentation to get API keys for monitored URLs"
        />
        <Section>
          <div
            className="col-12 d-block mb_20 webhook-doc"
            style={{ maxWidth: '1200px', width: '100%' }}
          >
            <h5>There are two ways of webhook connections</h5>
            <p className="text-title">First type of webhook connection is connected to account and each time an change is detected on one of your monitoring URLs in Hexowatch the JSON formatted data of the change will be sent to your provided webhook URL</p>
            <h6>To use this type of webhook connection</h6>
            <ol>
              <li>
                <p>Go into Hexowatch Settings</p>
                <img src={settginsImg} alt="settings" style={{ width: '100%' }} />
              </li>
              <li>

                <p>Select webhooks</p>
                <img src={webhookImg} alt="webhook" style={{ width: '100%' }} />
              </li>
              <li>

                <p>Put your webhook URL inside webhook field</p>
                <img src={webhookUrlImg} alt="input" style={{ width: '100%' }} />
              </li>
            </ol>
            <br />
            <p className="text-title">Second type of webhook connection is per monitoring URL, you can connect to any monitoring URL of your Hexowatch account individually and each time a change is detected on specifically  that monitoring URL the JSON formatted data of the change will be sent to your provided webhook URL</p>
            <h6>To use this type of webhook connection</h6>
            <ol>
              <li>
                <p>Choose monitoring URL</p>
                <img src={monitoringImg} alt="monitoring urls" style={{ width: '100%' }} />
              </li>
              <li>

                <p>Click on update icon</p>
                <img src={monitoringWebhookImg} alt="edit" style={{ width: '100%' }} />
              </li>
              <li>

                <p>Put your webhook URL in webhook input</p>
                <img src={monitoringWebhookInputImg} alt="input" style={{ width: '100%' }} />
              </li>
            </ol>
            <br />
            <h5>JSON formatted Data of the Event is the following</h5>
            <div className="json-title-block">
              <span>JSON representation</span>
            </div>
            {/* <pre className="pre">
              <p>{'{'}</p>
              <p className="mgl-25">"id": "unique id of the event",</p>
              <p className="mgl-25">"title": "Monitoring Tool name",</p>
              <p className="mgl-25">"name": "Monitoring URL name"</p>
              <p className="mgl-25">"monitoredUrl": "Monitoring URL address"</p>
              <p className="mgl-25">"createdDate": "Date of creation",</p>
              <p className="mgl-25">"changePercentage": "percentage of the Event change",</p>
              <p className="mgl-25">"link": "link to the event page in Hexowatch",</p>
              <p className="mgl-25">"diff_data": "Difference data (if exists)",</p>
              <p className="mgl-25">"new_data": "new captured data (if exists)",</p>
              <p className="mgl-25">"old_data": "old captured data (if exists)"</p>
              <p >{'{'}</p>
            </pre> */}

            {/* [ */}
            {/* {
    "id": "unique id of the event",
    "title": "title of the event",
    "monitoredUrl": "the url that the event was detected",
    "name": "name of the monitored url",
    "createdDate": "the date of the event",
    "changePercentage": "change that was detected, IF ANY",
    "link": "link to event page on hexowatch.com",
    "old_data": "old data",
    "new_data": "new data",
    "diff_data": "difference data",
    "condition_values": [
      "condition values IF ANY"
    ],
    "tags": [
      "watch tags IF ANY"
    ],
    "element_content": [
      "element content with HTML code or null"
    ],
    "element_content_keyword": "element content keyword or null",
    "element_parsed_data": [
      "element parsed data array of { innerHTML: string } like objects or null"
    ]
  }
] */}

{/* "backlinkToolData": {
      "deletions": [],
      "updates": [],
      "additions": []
    }, */}

            <pre className="pre">
              <p>{'['}</p>
              <p className="mgl-25">{'{'}</p>
              <p className="mgl-50">"id": "unique id of the change",</p>
              <p className="mgl-50">"title": "title of the change",</p>
              <p className="mgl-50">"monitoredUrl": "the URL that the change was detected",</p>
              <p className="mgl-50">"name": "name of the monitored URL",</p>
              <p className="mgl-50">"createdDate": "the date of the change",</p>
              <p className="mgl-50">"changePercentage": "change that was detected, IF ANY",</p>
              <p className="mgl-50">"link": "link to change page on hexowatch.com",</p>
              <p className="mgl-50">"old_data": "old data",</p>
              <p className="mgl-50">"new_data": "new data",</p>
              <p className="mgl-50">"diff_data": "difference data",</p>
              <p className="mgl-50">"condition_values": [</p>
              <p className="mgl-75">"condition values IF ANY"</p>
              <p className="mgl-50">],</p>
              <p className="mgl-50">"tags": [</p>
              <p className="mgl-75">"watch tags IF ANY"</p>
              <p className="mgl-50">],</p>
              <p className="mgl-50">"element_content": [</p>
              <p className="mgl-75">"element content with HTML code or null"</p>
              <p className="mgl-50">],</p>
              <p className="mgl-50">"element_content_keyword": "element content keyword or null",</p>
              <p className="mgl-50">"element_parsed_data": [</p>
              <p className="mgl-75">"element parsed data"</p>
              <p className="mgl-50">],</p>

              <p className="mgl-50">"backlinkToolData": {'{'}</p>
              <p className="mgl-75">"deletions": [],</p>
              <p className="mgl-75">"updates": [],</p>
              <p className="mgl-75">"additions": []</p>
              <p className="mgl-50">{'}'},</p>
              
              <p className="mgl-50">"techStackToolData": {'{'}</p>
              <p className="mgl-75">"deletions": [],</p>
              <p className="mgl-75">"updates": [],</p>
              <p className="mgl-75">"additions": []</p>
              <p className="mgl-50">{'}'},</p>

              <p className="mgl-50">"sitemapToolData": {'{'}</p>
              <p className="mgl-75">"deletions": [],</p>
              <p className="mgl-75">"updates": [],</p>
              <p className="mgl-75">"additions": []</p>
              <p className="mgl-50">{'}'},</p>

              <p className="mgl-50">"sectionScreenToolData": {'{'}</p>
              <p className="mgl-75">"deletions": [],</p>
              <p className="mgl-75">"updates": [],</p>
              <p className="mgl-75">"additions": []</p>
              <p className="mgl-50">{'}'},</p>

              <p className="mgl-50">"keywordSectionToolData": {'{'}</p>
              <p className="mgl-75">"deletions": [],</p>
              <p className="mgl-75">"updates": [],</p>
              <p className="mgl-75">"additions": []</p>
              <p className="mgl-50">{'}'},</p>

              <p className="mgl-50">"pingToolData": {'{'}</p>
              <p className="mgl-75">"deletions": [],</p>
              <p className="mgl-75">"updates": [],</p>
              <p className="mgl-75">"additions": []</p>
              <p className="mgl-50">{'}'},</p>

              <p className="mgl-50">"htmlSectionToolData": {'{'}</p>
              <p className="mgl-75">"deletions": [],</p>
              <p className="mgl-75">"updates": [],</p>
              <p className="mgl-75">"additions": []</p>
              <p className="mgl-50">{'}'},</p>

              <p className="mgl-50">"contentSectionToolData": {'{'}</p>
              <p className="mgl-75">"deletions": [],</p>
              <p className="mgl-75">"updates": [],</p>
              <p className="mgl-75">"additions": []</p>
              <p className="mgl-50">{'}'},</p>

              <p className="mgl-50">"sectionElementToolData": {'{'}</p>
              <p className="mgl-75">"deletions": [],</p>
              <p className="mgl-75">"updates": [],</p>
              <p className="mgl-75">"additions": []</p>
              <p className="mgl-50">{'}'},</p>

              <p className="mgl-50">"httpRequestToolData": {'{'}</p>
              <p className="mgl-75">"deletions": [],</p>
              <p className="mgl-75">"updates": [],</p>
              <p className="mgl-75">"additions": []</p>
              <p className="mgl-50">{'}'}</p>

              <p className="mgl-25">{'}'}</p>
              <p >{']'}</p>
            </pre>
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default WebhookIntegration;
